import React, { useEffect, useState } from 'react';
import Footer from './Footer'
import Header from './Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import robo from "../Assets/images/robo1.svg";
import lightrobo from "../Assets/images/roboleft.png";
import downrobo from "../Assets/images/scanrobo.png";
import coinrobo from "../Assets/images/Background.svg";
import sitrobo from "../Assets/images/sitrobo.svg";

import robos from "../Assets/images/robos.svg";
import alien from "../Assets/images/alien.svg";
import siderobo from "../Assets/images/siderobo.svg";
import leftrobo from "../Assets/images/leftrobo.svg";

import crossline from "../Assets/images/cross-line-1.png";
import ellipse from "../Assets/images/Ellipse.svg";
import polygon from "../Assets/images/Polygon.svg";
import polygon2 from "../Assets/images/polygon2.png";
import leftside from "../Assets/images/leftside.png";

import ploylight from "../Assets/images/ploylight.png";

import lastline from "../Assets/images/lastline.png"

import circle from "../Assets/images/circle.png";

import greenrectangle from "../Assets/images/greenrectangle.png"

import AOS from 'aos';
import 'aos/dist/aos.css';

import Typed from 'react-typed';

const Home = () => {

    useEffect(() => {
        AOS.init();


        // // Initialising the canvas
        // var canvas = document.querySelector('canvas'),
        //     ctx = canvas.getContext('2d');

        // // Setting the width and height of the canvas
        // canvas.width = window.innerWidth;
        // canvas.height = window.innerHeight;

        // // Setting up the letters
        // var letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZ';
        // letters = letters.split('');

        // // Setting up the columns
        // var fontSize = 10,
        //     columns = canvas.width / fontSize;

        // // Setting up the drops
        // var drops = [];
        // for (var i = 0; i < columns; i++) {
        //   drops[i] = 1;
        // }

        // // Setting up the draw function
        // function draw() {
        //   ctx.fillStyle = 'rgba(0, 0, 0, .1)';
        //   ctx.fillRect(0, 0, canvas.width, canvas.height);
        //   for (var i = 0; i < drops.length; i++) {
        //     var text = letters[Math.floor(Math.random() * letters.length)];
        //     ctx.fillStyle = '#0f0';
        //     ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        //     drops[i]++;
        //     if (drops[i] * fontSize > canvas.height && Math.random() > .95) {
        //       drops[i] = 0;
        //     }
        //   }
        // }

        // // Loop the animation
        // setInterval(draw, 33);

    }, [])

    const responsive1 = {

        0: {
            items: 1,
        },
        424: {
            items: 1,
        },
        576: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    }





    return (
        <>
            <div className='land'>
                <Header />

                <div className='uidev'>

                    <div className='container container-theme'>
                        <section className='banner firstsection'>
                            <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradient1'></span>
                            <span className='violentgradient'></span>
                            <img className='img-fluid crossline' src={crossline} alt='alirobo' />
                            <img className='img-fluid ellipse' src={ellipse} alt='alirobo' />
                            <img className='img-fluid polygon' src={polygon} alt='alirobo' />
                            <img className='img-fluid polygon2' src={polygon2} alt='alirobo' />
                            <img className='img-fluid leftside' src={leftside} alt='alirobo' />
                            <div className='row align-items-center'>
                                <div className='col-md-6 mt-3'>
                                    <OwlCarousel className='owl-theme' responsive={responsive1} loop margin={10} nav>
                                        <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn" data-aos="fade-right" data-aos-duration="1000">Lorem Ipsum is Simply Dummy</button>
                                                {/* <h2 className='mt-3 title' data-aos="fade-right" data-aos-duration="1500">A Blockchain Protocol Designed for the</h2> */}
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={['A Blockchain Protocol Designed for the']}
                                                        typeSpeed={130}
                                                        cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub' data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'>AI </span> Revolution</h2>

                                                <div className='backimg' data-aos="fade-down" data-aos-duration="1500">
                                                    <p className='mb-0 texts'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">Lorem Ipsum is Simply Dummy</button>
                                                {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={['A Blockchain Protocol Designed for the']}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>AI </span> Revolution</h2>
                                                <div className='backimg mt-2'>
                                                    <p className='mb-0 texts'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">Lorem Ipsum is Simply Dummy</button>
                                                {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={['A Blockchain Protocol Designed for the']}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>AI </span> Revolution</h2>
                                                <div className='backimg'>
                                                    <p className='mb-0 texts'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">Lorem Ipsum is Simply Dummy</button>
                                                {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={['A Blockchain Protocol Designed for the']}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>AI </span> Revolution</h2>
                                                <div className='backimg'>
                                                    <p className='mb-0 texts'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">Lorem Ipsum is Simply Dummy</button>
                                                {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={['A Blockchain Protocol Designed for the']}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>AI </span> Revolution</h2>
                                                <div className='backimg'>
                                                    <p className='mb-0 texts'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap</p>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                                <div className='col-md-6 mt-3' 
                                // data-aos="fade-up" 
                                >
                                    <img className='img-fluid yy' src={robo} alt='alirobo' />

                                </div>
                            </div>
                        </section>

                        <section className='userapp'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1" style={{ display:"none" }}></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-12 mt-5' data-aos="fade-down" data-aos-duration="1000">
                                    <div className='backimg1'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 col-sm-3'>
                                                <img className='img-fluid defrobo' src={downrobo} alt='alirobo' />
                                                <span className='greengradient'></span>
                                            </div>
                                            <div className='col-md-12 col-sm-9 lefttext'>
                                                <h4 data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'>Lor</span>em Ipsum is simply</h4>
                                                <p data-aos="fade-right" data-aos-duration="1000">simply dummy Lorem</p>
                                                {/* <div className='mb-3 titledr'>
                                                    <Typed
                                                        strings={['simply dummy Lorem']}
                                                        typeSpeed={40}
                                                        loop
                                                    />
                                                </div> */}
                                                <p className='sub-text mb-0' data-aos="fade-right" data-aos-duration="1000">Lorem Ipsum is simply dummy text of the printing and typesetting industry. ever since the 1500s when an unknown printer...</p>
                                                <button type="button" class="btn userbtn useappbtn">USE APP</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 mt-5' data-aos="fade-down" data-aos-duration="1000">
                                    <div className='backimg1'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 col-sm-3 text-center'>
                                                <img className='img-fluid defrobo' src={lightrobo} alt='alirobo' />   
                                                <span className='greengradient'></span>
                                            </div>
                                            <div className='col-md-12 col-sm-9 lefttext'>
                                                <h4 data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'>Lor</span>em Ipsum is simply</h4>
                                                <p data-aos="fade-right" data-aos-duration="1000">simply dummy</p>
                                                {/* <div className='mb-3 titledr'>
                                                    <Typed
                                                        strings={['simply dummy Lorem']}
                                                        typeSpeed={40}
                                                        loop
                                                    />
                                                </div> */}
                                                <p className='sub-text mb-0' data-aos="fade-right" data-aos-duration="1500">Lorem Ipsum is simply dummy text of the printing and typesetting industry.  text ever since the 1500s when an unknown printer...</p>
                                                <button type="button" class="btn userbtn useappbtn">USE APP</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='banner'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientlefts'></span>
                            <img className='img-fluid crossline1' src={crossline} alt='alirobo' />
                            <img className='img-fluid circle' src={circle} alt='alirobo' />
                            <img className='img-fluid greenrectangle' src={greenrectangle} alt='alirobo' />
                            <img className='img-fluid ployright' src={ploylight} alt='alirobo' />
                            <span className='greengradient2'></span>
                            <div className='row align-items-center'>
                                <div className='col-md-6 mt-4 mb-4' data-aos="fade-up">
                                    <img className='img-fluid roboo yy' src={coinrobo} alt='alirobo' />
                                </div>
                                <div className='col-md-6 mt-4'>
                                    <div>
                                        <button type="button" class="btn userbtn headingbtn" 
                                        // data-aos="fade-left" data-aos-duration="1500"
                                        >Lorem Ipsum is Simply Dummy</button>
                                        {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                        <div className='mt-3 titlelist'>
                                            <Typed
                                                strings={['A Blockchain Protocol Designed for the']}
                                                typeSpeed={130}
                                                cursorChar={"_"}
                                                loop
                                            />
                                        </div>
                                        <h2 className='mt-0 title title-sub' 
                                        // data-aos="fade-left" data-aos-duration="1500"
                                        ><span className='titlespan'>AI </span> Revolution</h2>
                                        <div className='backimg' 
                                        data-aos="fade-up" 
                                        // data-aos-duration="1500"
                                        >
                                            <p className='mb-0 texts'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='banner bannerlast'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientround'></span>
                            <img className='img-fluid leftline' src={lastline} alt='alirobo' />
                            <div className='row align-items-center'>
                                <div className='col-md-6 mt-4'>
                                    <div>
                                        <button type="button" class="btn userbtn headingbtn" 
                                        // data-aos="fade-right" data-aos-duration="1500"
                                        >Lorem Ipsum is Simply Dummy</button>
                                        {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                        <div className='mt-3 titlelist'>
                                            <Typed
                                                strings={['A Blockchain Protocol Designed for the']}
                                                typeSpeed={130}
                                                cursorChar={"_"}
                                                loop
                                            />
                                        </div>
                                        <h2 className='mt-0 title title-sub' 
                                        // data-aos="fade-right" data-aos-duration="1500"
                                        ><span className='titlespan'>AI </span> Revolution</h2>
                                        <div className='backimg mt-3' 
                                     data-aos="fade-up" 
                                    //  data-aos-duration="1500"
                                        >
                                            <p className='mb-0 texts'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-6 mt-4' 
                                data-aos="fade-up" 
                                // data-aos-duration="1500"
                                >
                                    <img className='img-fluid roboo' src={sitrobo} alt='alirobo' />
                                </div>

                            </div>
                        </section>

                        <section className='banner'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientleft'></span>
                            <span className='greengradientright'></span>
                            <span className='greengradientcenter'></span>
                            <img className='img-fluid circle circle1' src={circle} alt='alirobo' />
                            <img className='img-fluid lastline' src={lastline} alt='alirobo' />
                            <div className='text-center'>
                                <button type="button" class="btn userbtn headingbtn">Lorem Ipsum is Simply Dummy</button>
                                {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                <div className='mt-3 titlelist'>
                                    <Typed
                                        strings={['A Blockchain Protocol Designed for the']}
                                        typeSpeed={130}
                                        cursorChar={"_"}
                                        loop
                                    />
                                    
                                </div>
                                <h2 className='mt-0 title title-sub'><span className='titlespan'>AI </span> Revolution</h2>
                            </div>
                            <div>
                                <div className='row mt-2'>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                                    <img className='img-fluid roboo1' src={robos} alt='alirobo' />
                                                    <button type="button" class="btn nobtn noappbtn">1</button>
                                                </div>
                                            </div>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>A Blockchain Protocol</p>
                                                <h5><span className='titlespan'>AI</span> Revolution</h5>
                                                <p className='aitext mt-3 mb-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                                <button type="button" class="btn userbtn">Read More</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                                    <img className='img-fluid roboo1' src={alien} alt='alirobo' />
                                                    <button type="button" class="btn nobtn noappbtn">2</button>
                                                </div>
                                            </div>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>A Blockchain Protocol</p>
                                                <h5><span className='titlespan'>AI</span> Revolution</h5>
                                                <p className='aitext mt-3 mb-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                                <button type="button" class="btn userbtn">Read More</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>A Blockchain Protocol</p>
                                                <h5><span className='titlespan'>AI</span> Revolution</h5>
                                                <p className='aitext mt-3 mb-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                                <button type="button" class="btn userbtn">Read More</button>
                                            </div>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                                    <img className='img-fluid roboo1' src={siderobo} alt='alirobo' />
                                                    <button type="button" class="btn nobtn noappbtn">3</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>A Blockchain Protocol</p>
                                                <h5><span className='titlespan'>AI</span> Revolution</h5>
                                                <p className='aitext mt-3 mb-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                                <button type="button" class="btn userbtn">Read More</button>
                                            </div>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                                    <img className='img-fluid roboo1' src={leftrobo} alt='alirobo' />
                                                    <button type="button" class="btn nobtn noappbtn">4</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                <Footer />
            </div>

        </>
    )


}

export default Home;  